import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { UtilService } from "src/app/services/util.service";
import { NavController, ModalController } from "@ionic/angular";
import Swal from "sweetalert2";
import OneSignal from 'onesignal-cordova-plugin';


import swal from "sweetalert2";
import { map } from "rxjs/operators";
import { VeggieFormComponent } from "src/app/components/veggie-form/veggie-form.component";
declare var google: any;

@Component({
  selector: "app-tracker",
  templateUrl: "./tracker.page.html",
  styleUrls: ["./tracker.page.scss"],
})
export class TrackerPage implements OnInit {
  @ViewChild("map", { static: true }) mapElement: ElementRef;

  map: any;
  latOri = "";
  longOri = "";
  map_loaded: boolean = false;
  veggie_form: boolean = true;
  latDest = "";
  longDest = "";
  id: any = "";
  diet_type: any;
  diet_info: any;
  addressTitle: any;
  cabify: any;
  cover: any = "";
  logo: any = "";
  name: any = "";
  info: any = "";
  dName: any = "";
  restAddress: any = "";
  clientAddress: any = "";
  dCover: any = "";
  dId: any;
  paid: any;
  phone: any = "";
  status: any = "";
  message: any = "";
  takeawayMessage: any = "";
  status2: any = "";
  totalOrders: any[] = [];
  grandTotal: any = "";
  createdTime: any = "";
  paidAt: any = "";
  acceptedTime: any = "";
  estimatedPreparationTime: any = "";
  estimatedPreparationTime10: any = "";
  onRouteTime: any = "";
  cabifyPickingAt: any = "";
  cabifyDeliverAt: any = "";
  christmas: boolean = false;
  takeaway: boolean = false;
  christmas_scheduled: any = 0
  driverLat: any = "";
  driverLng: any = "";
  interval: any = "";
  maps: any = "";
  dMarker: any = "";
  dMarker2: any = "";
  orders: any;
  restFCM: any = "";
  driverFCM: any = "";
  cName: any = "";
  cCover: any = "";
  cId: any = "";
  cModel: any = "";
  cColor: any = "";
  cPlate: any = "";
  vid: any;
  deliveryName: any;
  estimatedPickUpTime: any;
  estimatedDropOffTime: any;
  deliveryTransport: any;
  proof_of_delivery_code: any;
  constructor(
    private route: ActivatedRoute,
    private api: ApisService,
    private adb: AngularFirestore,
    private util: UtilService,
    private router: Router,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      // console.log("data=>", data);
      if (data.hasOwnProperty("id")) {
        this.id = data.id;
        this.getOrderReal();



      }
    });
  }


  callDriver() {
    // window.open('tel:' + this.phone);
    if (!this.phone) {
      this.openWhatsapp(this.status)
    } else {
      let phone = String(this.phone)[0] == "+" ? this.phone.slice(1) : this.phone
      window.open("https://api.whatsapp.com/send?phone=" + phone);

    }
  }

  async openModal() {
    console.log(
      this.name,
      this.diet_type,
      this.id
    )
   
    
    const modal = await this.modalController.create({
      component: VeggieFormComponent, // Specify the component to use as modal content
      componentProps: {
        name: this.name,
        diet_type: this.diet_type ? this.diet_type : 'vegan',
        diet_info: this.diet_info ? this.diet_info : null,
        orderId: this.id,
        vegan_venue: this.info.vid.vegan ? true : false,
        user_answered: this.info.uid.veggie_form_answered ? true : false
      },
      cssClass: 'mobile-modal-css', 
      backdropDismiss: true,
    });
  
  
    await modal.present();
    const { data } = await modal.onDidDismiss();
    console.log('Modal dismissed', data);
  
    // Call your function here
    this.onModalDismiss(data);
  }


  onModalDismiss(data: any) {
    // You can handle data returned from the modal
    if (data) {
      console.log('Modal returned data:', data);
     
    } else {

      console.log('Modal was dismissed without returning data');
    }
    const param2 = {
      veggie_form_answered : true
    }
    this.api.updateProfile(localStorage.getItem("uid"),param2).then(response=>{
      this.util.hide()
      console.log("listo a mostrar feedback.")
    })
  }
  
  getOrderReal() {
    this.adb
      .collection("orders")
      .doc(this.id)
      .snapshotChanges()
      .pipe(map((data) => data.payload.data()))
      .subscribe(
        async (order: any) => {
          let data = order;
          if (data) {
            if (data.status == 'delivered') {
              const navData: NavigationExtras = {
                queryParams: {
                  id: this.id,
                },
              };
              this.router.navigate(["/review-order"], navData);
            }

            this.info = data
            this.takeaway = data.takeaway
            console.log('data', data)
            this.diet_type = data.uid.diet_type
            this.diet_info = data.uid.diet_info ? data.uid.diet_info : null
            let infoResto;
            await data.vid.get().then(function (doc) {
              data.vid = doc.data();

              data.vid.id = doc.id;
              infoResto = data.vid;
              console.log('infoResto', infoResto)
              if (!infoResto.vegan) {
                console.log('no es 100% vegano el local')
                if (!data.veggie_form) {


                }
              }
            });
            this.name = infoResto.name
            
            // console.log("orderrrrrrr:I", infoResto.tokenNotification);
            this.restFCM = infoResto.tokenNotification ? infoResto.tokenNotification : null;
            await data.uid.get().then(function (doc) {
              data.uid = doc.data();
              data.uid.id = doc.id;
            });
            this.diet_type = data.uid.diet_type
            if (!data.veggie_form) {
              this.openModal()
            }
            if (data && data.dId && !data.dId.cabify) {
              await data.dId.get().then(function (doc) {
                data.dId = doc.id;
                data.dId = doc.data();
              });
            }
            if (data.dId && !data.dId.cabify) {
              this.dName = data.dId.fullname;
              this.dCover = data.dId.coverImage;
              this.dId = data.dId.uid;
              this.phone = data.dId.phone;

              // console.log("esta es la data did", data.dId);
            }
            if (data.dId && data.dId.cabify && data.dId.cabify.driver) {
              this.cName = data.dId.cabify.driver.name;
              this.cCover = data.dId.cabify.driver.photo_url;
              this.cId = data.dId.cabify.asset.reg_plate;
              this.phone = data.dId.cabify.driver.phone;
              this.cModel = data.dId.cabify.asset.name;
              this.cColor = data.dId.cabify.asset.color;
              this.cPlate = data.dId.cabify.asset.reg_plate;

            }
            this.proof_of_delivery_code = data.proof_of_delivery_code ? data.proof_of_delivery_code : null
            this.paid = data.paid;
            const createdTime = new Date(data.createdAt.seconds * 1000)
              .toLocaleTimeString()
              .split(":");
            this.createdTime = createdTime[0] + ":" + createdTime[1];
            if (data.acceptedAt) {
              const acceptedTime = new Date(data.acceptedAt.seconds * 1000)
                .toLocaleTimeString()
                .split(":");
              this.acceptedTime = acceptedTime[0] + ":" + acceptedTime[1];
            }
            if (data.estimatedPreparationTime) {
              const estimatedPreparationTime = new Date(data.estimatedPreparationTime.seconds * 1000)
                .toLocaleTimeString()
                .split(":");

              const estimatedPreparationTime2 = new Date(data.estimatedPreparationTime.seconds * 1000 + 10 * 60 * 1000)
                .toLocaleTimeString()
                .split(":");
              console.log("el tiempo estiamdo de que este la comida:", estimatedPreparationTime)
              // if (new Date() > new Date(data.estimatedPreparationTime.seconds * 1000)) {
              //   console.log("entra aca")
              //   this.estimatedPreparationTime = null
              //   this.estimatedPreparationTime10 = null
              // } else {
              this.estimatedPreparationTime = estimatedPreparationTime[0] + ":" + estimatedPreparationTime[1];
              this.estimatedPreparationTime10 = estimatedPreparationTime2[0] + ":" + estimatedPreparationTime2[1];
              // }
            }
            this.mensajeStatus();

            if (data.onRouteAt) {
              const onRouteTime = new Date(data.onRouteAt.seconds * 1000)
                .toLocaleTimeString()
                .split(":");
              this.onRouteTime = onRouteTime[0] + ":" + onRouteTime[1];
            }
            if (data.payed_at) {
              const payedTime = new Date(data.payed_at.seconds * 1000)
                .toLocaleTimeString()
                .split(":");
              this.paidAt = payedTime[0] + ":" + payedTime[1];
            }
            // console.log("REST ADDRESS", data.vid);
            this.christmas = data.christmas ? true : false
            this.restAddress = data.vid.address.split(",")[0];
            this.cover = data.vid.cover;
            this.logo = data.vid.logo;
            this.name = data.vid.name;
            this.status = data.status;
            this.status2 = data.status;
            this.grandTotal = data.grandTotal;
            let timestamp = Math.floor(Date.now() / 1000);

            try {
              OneSignal.sendTags({
                "last_order": timestamp,
                "amount_spent": data.grandTotal,
                "orders": "1"
              });
            } catch (error) {
              // console.log("Onesignal Error", error);
            }

            this.addressTitle = data.vid.title ? data.vid.title : null;
            let new3 = data.address.address.split(",");
            this.clientAddress = new3[0];
            this.totalOrders = JSON.parse(data.order);
            // this.getDriverInfo();
            this.totalOrders.forEach(food => {
              if (food.christmas) {
                this.christmas = true
              }
              if (food.christmas && food.christmas_scheduled) {
                this.christmas_scheduled += 1
              }
            })
            // console.log("my order", this.totalOrders);
            if (data.dId && data.dId.cabify) {
              this.cabify = data.dId.cabify;
              // console.log("dame la info cabify", data.dId.cabify);
              if (data.dId.cabify.tracking && data.dId.cabify.tracking.routes) {
                if (data.dId.cabify.tracking.routes.pick_up) {
                  var timePickup = new Date();
                  timePickup.setSeconds(
                    timePickup.getSeconds() +
                    data.dId.cabify.tracking.routes.pick_up.eta
                  );

                  const cabifyPickingAt = new Date(timePickup)
                    .toLocaleTimeString()
                    .split(":");
                  this.cabifyPickingAt =
                    cabifyPickingAt[0] + ":" + cabifyPickingAt[1];
                }

                if (data.dId.cabify.tracking.routes.drop_off) {
                  var timeDropoff = new Date();
                  timeDropoff.setSeconds(
                    timeDropoff.getSeconds() +
                    data.dId.cabify.tracking.routes.drop_off.eta
                  );

                  const cabifyDeliverAt = new Date(timeDropoff)
                    .toLocaleTimeString()
                    .split(":");
                  this.cabifyDeliverAt =
                    cabifyDeliverAt[0] + ":" + cabifyDeliverAt[1];
                  // console.log("=========>", this.cabifyDeliverAt);

                }
              }
              this.locationUpdates(
                data.vid.lat,
                data.vid.lng,
                data.address.lat,
                data.address.lng
              );
            }

            // if (
            //   data.dId &&
            //   data.dId.cabify &&
            //   data.dId.cabify.tracking &&
            //   data.dId.cabify.tracking.location.lat &&
            //   data.dId.cabify.tracking.location.lng
            // ) {
            // if (data.dId.cabify.tracking) {
            //   // console.log("por donde andas", data.dId.cabify.tracking);

            //   if (data.dId.cabify.tracking.routes.pick_up) {
            //     var timePickup = new Date();
            //     timePickup.setSeconds(
            //       timePickup.getSeconds() +
            //       data.dId.cabify.tracking.routes.pick_up.eta
            //     );

            //     const cabifyPickingAt = new Date(timePickup)
            //       .toLocaleTimeString()
            //       .split(":");
            //     this.cabifyPickingAt =
            //       cabifyPickingAt[0] + ":" + cabifyPickingAt[1];
            //     // console.log("=========>", this.cabifyPickingAt);
            //   }

            //   if (data.dId.cabify.tracking.routes.drop_off) {
            //     var timeDropoff = new Date();
            //     timeDropoff.setSeconds(
            //       timeDropoff.getSeconds() +
            //       data.dId.cabify.tracking.routes.drop_off.eta
            //     );

            //     const cabifyDeliverAt = new Date(timeDropoff)
            //       .toLocaleTimeString()
            //       .split(":");
            //     this.cabifyDeliverAt =
            //       cabifyDeliverAt[0] + ":" + cabifyDeliverAt[1];
            //     // console.log("=========>", this.cabifyDeliverAt);
            //   }
            // }
            // this.loadMap(
            //   parseFloat(data.vid.lat),
            //   parseFloat(data.vid.lng),
            //   parseFloat(data.address.lat),
            //   parseFloat(data.address.lng),
            //   parseFloat(data.dId.cabify.tracking.location.lat),
            //   parseFloat(data.dId.cabify.tracking.location.lng),
            // );
            // } else 
            if (data.dId) {
              this.loadMap(
                parseFloat(data.vid.lat),
                parseFloat(data.vid.lng),
                parseFloat(data.address.lat),
                parseFloat(data.address.lng),
                parseFloat(data.dId.lat),
                parseFloat(data.dId.lng)
              );
            } else {
              this.loadMap(
                parseFloat(data.vid.lat),
                parseFloat(data.vid.lng),
                parseFloat(data.address.lat),
                parseFloat(data.address.lng),
                null,
                null
              );
            }
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  locationUpdates(vLat, vLng, addressLat, addressLng) {
    this.adb
      .collection("locationUpdates")
      .doc(this.id)
      .snapshotChanges()
      .pipe(map((data) => data.payload.data()))
      .subscribe(
        async (order: any) => {
          let data = order;

          if (data) {
            // console.log("la data de locationUpdates", data);
            this.cabify.tracking.location.lat = data.location.latitude;
            this.cabify.tracking.location.lon = data.location.longitude;
            if (!this.map_loaded) {
              this.loadMap(
                parseFloat(vLat),
                parseFloat(vLng),
                parseFloat(addressLat),
                parseFloat(addressLng),
                parseFloat(data.location.latitude),
                parseFloat(data.location.longitude)
              );
            } else {
              if (!this.dMarker) {
                // console.log("no existe el dmarker");

                if (this.dMarker2) {
                  this.dMarker2.setMap(null);
                  this.dMarker2 = null;
                  // console.log("existe el dmarker2, pero ahora no mas");
                }

                const driverPos = new google.maps.LatLng(
                  data.location.latitude,
                  data.location.longitude
                );

                const icon = {
                  url: "assets/bicycle.svg",
                  scaledSize: new google.maps.Size(30, 30), // scaled size
                  origin: new google.maps.Point(0, 0), // origin
                  anchor: new google.maps.Point(15, 15), // anchor
                };
                this.dMarker = new google.maps.Marker({
                  map: this.maps,
                  position: driverPos,
                  icon: icon,
                });
                this.changeMarkerPosition(this.dMarker, driverPos);
              } else {
                // console.log("ya existe el dmarker");

                if (this.dMarker) {
                  this.dMarker.setMap(null);
                  this.dMarker = null;
                }
                const driverPos = new google.maps.LatLng(
                  data.location.latitude,
                  data.location.longitude
                );

                const icon = {
                  url: "assets/bicycle.svg",
                  scaledSize: new google.maps.Size(30, 30), // scaled size
                  origin: new google.maps.Point(0, 0), // origin
                  anchor: new google.maps.Point(15, 15), // anchor
                };
                this.dMarker2 = new google.maps.Marker({
                  map: this.maps,
                  position: driverPos,
                  icon: icon,
                });

                this.changeMarkerPosition(this.dMarker2, driverPos);
              }
              let destinationA
              if (this.status == "assigned" || this.status == "picking_up") {
                destinationA = { lat: vLat, lng: vLng };
              } else {
                destinationA = { lat: addressLat, lng: addressLng };
              }

              if (this.status != "picking_up" && this.status != "arrived" && this.status != "delivered" && this.status != "cancel" && this.status != "rejected") {
                var directionsService = new google.maps.DirectionsService();
                var directionsDisplay = new google.maps.DirectionsRenderer();

                directionsDisplay = new google.maps.DirectionsRenderer();
                var bounds = new google.maps.LatLngBounds();

                var origin1 = {
                  lat: data.location.latitude,
                  lng: data.location.longitude,
                };

                directionsDisplay.setMap(this.maps);
                // directionsDisplay.setOptions({ suppressMarkers: true });
                // directionsDisplay.setOptions({
                //   polylineOptions: {
                //     strokeWeight: 2,
                //     strokeOpacity: 1,
                //     strokeColor: "#9d3dbf",
                //   },
                //   suppressMarkers: true,
                // });
                // var geocoder = new google.maps.Geocoder();

                // var service = new google.maps.DistanceMatrixService();
                // let self = this
                // service.getDistanceMatrix(
                //   {
                //     origins: [origin1],
                //     destinations: [destinationA],
                //     travelMode: "DRIVING",
                //     unitSystem: google.maps.UnitSystem.METRIC,
                //     avoidHighways: false,
                //     avoidTolls: false,
                //   },
                //   function (response, status) {
                //     if (status !== "OK") {
                //       alert("Error was: " + status);
                //     } else {
                //       var originList = response.originAddresses;
                //       var destinationList = response.destinationAddresses;
                //       // var outputDiv = document.getElementById("output");
                //       // outputDiv.innerHTML = '';
                //       // deleteMarkers(markersArray);

                //       var showGeocodedAddressOnMap = function (asDestination) {
                //         // var icon = asDestination ? destinationIcon : originIcon;
                //         return function (results, status) {
                //           if (status === "OK") {
                //             self.maps.fitBounds(
                //               bounds.extend(results[0].geometry.location)
                //             );
                //             // markersArray.push(new google.maps.Marker({
                //             //   map: map,
                //             //   position: results[0].geometry.location,
                //             //   icon: icon
                //             // }));
                //           } else if (
                //             status ==
                //             google.maps.GeocoderStatus.OVER_QUERY_LIMIT
                //           ) {
                //             // console.log("entra en overquerys:", status);

                //             setTimeout(() => {
                //               self.maps.fitBounds(
                //                 bounds.extend(results[0].geometry.location)
                //               );
                //             }, 3000);
                //           } else if (status == "ERROR") {
                //             setTimeout(() => {
                //               self.maps.fitBounds(
                //                 bounds.extend(results[0].geometry.location)
                //               );
                //             }, 6000);
                //           } else {
                //             alert(
                //               "Geocode was not successful due to: " + status
                //             );
                // }
                // };
                // };

                //   directionsService.route(
                //     {
                //       origin: origin1,
                //       destination: destinationA,
                //       travelMode: "DRIVING",
                //     },
                //     function (response, status) {
                //       if (status === "OK") {
                //         directionsDisplay.setDirections(response);
                //       } else {
                //         window.alert(
                //           "Directions request failed due to " + status
                //         );
                //       }
                //     }
                //   );

                //   for (let i = 0; i < originList.length; i++) {
                //     let results = response.rows[i].elements;
                //     geocoder.geocode(
                //       { address: originList[i] },
                //       showGeocodedAddressOnMap(true)
                //     );
                //     for (let j = 0; j < results.length; j++) {
                //       geocoder.geocode(
                //         { address: destinationList[j] },
                //         showGeocodedAddressOnMap(true)
                //       );
                //     }
                //   }
                // }
                // }
                // );
              }
            }
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  }
  // getOrder() {
  //   this.util.show();
  //   this.api
  //     .getOrderById(this.id).then((data) => {
  //       this.util.hide();
  //       // console.log(data);
  //       if (data) {
  //         if (data.dId && !data.dId.cabify) {
  //           this.dName = data.dId.fullname;
  //           this.dCover = data.dId.coverImage;
  //           this.dId = data.dId.uid;
  //           this.phone = data.dId.phone;

  //           // console.log("esta es la data did", data.dId)
  //         }
  //         if (data.dId && data.dId.cabify && data.dId.cabify.driver) {
  //           this.cName = data.dId.cabify.driver.name;
  //           this.cCover = data.dId.cabify.driver.photo_url;
  //           this.cId = data.dId.cabify.asset.reg_plate;
  //           this.phone = data.dId.cabify.driver.phone;
  //           this.cModel = data.dId.cabify.asset.name;
  //           this.cColor = data.dId.cabify.asset.color;
  //           this.cPlate = data.dId.cabify.asset.reg_plate;
  //           // console.log("esta es la data did", this.cName,this.cCover, this.dId, this.phone)
  //         }
  //         this.paid = data.paid;
  //         const createdTime = new Date(data.createdAt.seconds * 1000).toLocaleTimeString().split(':')
  //         this.createdTime = createdTime[0] + ':' + createdTime[1]
  //         if (data.acceptedAt) {
  //           const acceptedTime = new Date(data.acceptedAt.seconds * 1000).toLocaleTimeString().split(':')
  //           this.acceptedTime = acceptedTime[0] + ':' + acceptedTime[1]
  //         }
  //         if (data.onRouteAt) {
  //           const onRouteTime = new Date(data.onRouteAt.seconds * 1000).toLocaleTimeString().split(':')
  //           this.onRouteTime = onRouteTime[0] + ':' + onRouteTime[1]
  //         }
  //         this.restAddress = data.vid.address.split(",")[0];
  //         this.cover = data.vid.cover;
  //         this.name = data.vid.name;
  //         this.status = data.status;
  //         this.status2 = data.status;
  //         this.grandTotal = data.grandTotal;
  //         this.addressTitle = data.vid.title
  //         let new3 = data.address.address.split(",");
  //         this.clientAddress = new3[0];
  //         this.totalOrders = JSON.parse(data.order);
  //         // this.getDriverInfo();
  //         // console.log("my order", this.totalOrders);
  //         this.mensajeStatus();
  //         if(data.dId && data.dId.cabify){
  //           this.cabify = data.dId.cabify
  //         }
  //         if(data.dId && data.dId.cabify && data.dId.cabify.tracking && data.dId.cabify.tracking.location.lat && data.dId.cabify.tracking.location.lng){
  //           this.loadMap(
  //             parseFloat(data.address.lat),
  //             parseFloat(data.address.lng),
  //             parseFloat(data.vid.lat),
  //             parseFloat(data.vid.lng),
  //             parseFloat(data.dId.cabify.tracking.location.lat),
  //             parseFloat(data.dId.cabify.tracking.location.lng),
  //           );
  //         }else{

  //           this.loadMap(
  //             parseFloat(data.address.lat),
  //             parseFloat(data.address.lng),
  //             parseFloat(data.vid.lat),
  //             parseFloat(data.vid.lng),
  //             parseFloat(data.vid.lat),
  //             parseFloat(data.vid.lng),
  //           );
  //         }

  //       }
  //     },
  //     (error) => {
  //       // console.log("error in orders", error);
  //       this.util.hide();
  //       this.util.errorToast("Something went wrong");
  //     }
  //   )
  //   .catch((error) => {
  //     // console.log("error in order", error);
  //     this.util.hide();
  //     this.util.errorToast("Something went wrong");
  //   });
  // }
  // getDriverInfo() {
  //   this.adb
  //     .collection("orders")
  //     .doc(this.id)
  //     .snapshotChanges()
  //     .subscribe(
  //       (data: any) => {
  //         // console.log(data.payload.data());
  //         if (data) {
  //           this.status = data.payload.data().status;
  //           if (data.payload.data().status === "delivered") {
  //             swal.fire({
  //               title: "Pedido entregado",
  //               text: "Tu pedido ha sido entregado",
  //               icon: "success",
  //               backdrop: false,
  //             });
  //             this.navCtrl.navigateRoot(["home"]);
  //           }
  //         }
  //       },
  //       (error) => {
  //         // console.log(error);
  //       }
  //     );

  //   this.adb
  //     .collection("users")
  //     .doc(this.dId)
  //     .snapshotChanges()
  //     .subscribe(
  //       (data: any) => {
  //         // console.log(data.payload.data());
  //         if (data) {
  //           this.driverLat = data.payload.data().lat;
  //           this.driverLng = data.payload.data().lng;
  //         }
  //       },
  //       (error) => {
  //         // console.log(error);
  //       }
  //     );
  // }

  changeMarkerPosition(marker, dPos) {
    if (this.cabify && this.cabify.tracking) {


      // var latlng = new google.maps.LatLng(dlat,dlon);
      //map.setCenter(latlng);
      marker.setPosition(dPos);
      // console.log("Updating runner position");
    }
  }


  loadMap(latOri, lngOri, latDest, lngDest, latCab, lngCab) {
    this.map_loaded = true;
    // console.log("entra en load map");

    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer();
    directionsDisplay = new google.maps.DirectionsRenderer();
    var bounds = new google.maps.LatLngBounds();

    var origin1 = { lat: parseFloat(latOri), lng: parseFloat(lngOri) };
    var destinationA = { lat: latDest, lng: lngDest };

    // var destinationIcon = 'https://chart.googleapis.com/chart?' + 'chst=d_map_pin_letter&chld=D|FF0000|000000';
    // var originIcon = 'https://chart.googleapis.com/chart?' + 'chst=d_map_pin_letter&chld=O|FFFF00|000000';
    var destinationIcon = {
      url: "assets/home.svg",
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(15, 15), // anchor
    };
    var originIcon = {
      url: this.logo != null ? this.logo : this.cover,
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(15, 15), // anchor
    };
    console.log(latOri, lngOri)
    const midpointLat = !this.takeaway ? (latOri + latDest) / 2 : latOri
    const midpointLng = !this.takeaway ? (lngOri + lngDest) / 2 : lngOri
    const mapStyles = [
      {
        featureType: 'all',
        elementType: 'labels',
        stylers: [
          { visibility: 'off' } // Hide all labels
        ]
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Show country labels
        ]
      },
      {
        featureType: 'administrative.province',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Show province/state labels
        ]
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Show city labels
        ]
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Show neighborhood labels
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          { visibility: 'on' } // Show road labels
        ]
      }
    ];


    // Create the Google Map with custom styles
    this.maps = new google.maps.Map(this.mapElement.nativeElement, {
      center: { lat: midpointLat, lng: midpointLng },
      disableDefaultUI: true,
      zoom: 14,
      styles: mapStyles, // Apply custom styles
    });

    const restPos = new google.maps.LatLng(latOri, lngOri);
    const custPos = new google.maps.LatLng(latDest, lngDest);
    let driverPos
    if (latCab && lngCab) {
      driverPos = new google.maps.LatLng(latCab, lngCab);
    }

    if (!this.takeaway) {
      var marker = new google.maps.Marker({
        map: this.maps,
        position: custPos,
        animation: google.maps.Animation.DROP,
        icon: destinationIcon,
      });
      marker.setMap(this.maps);
    }

    var markerRest = new google.maps.Marker({
      map: this.maps,
      position: restPos,
      animation: google.maps.Animation.DROP,
      icon: originIcon,
    });
    markerRest.setMap(this.maps);

    const icon = {
      url: "assets/bicycle.svg",
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(15, 15), // anchor
    };
    if (
      this.status2 != "pending" &&
      this.status2 != "created" &&
      this.status2 != "accepted" && latCab && lngCab
    ) {
      this.dMarker2 = new google.maps.Marker({
        map: this.maps,
        position: driverPos,
        animation: google.maps.Animation.DROP,
        icon: icon,
      });
      this.dMarker2.setMap(this.maps);
    }


    // if (this.status2 != "pending" && this.status2 != "created" && this.status2 != "accepted" && this.status2 != "cancel" && this.status2 != "rejected" ) {
    //   if (this.dId && !this.dId.cabify) {
    //     this.interval = setInterval(() => {
    //       this.changeMarkerPosition(this.dMarker2);
    //     }, 12000);
    //   } else {
    //     this.changeMarkerPosition(this.dMarker2);
    //   }
    // }
  }

  ionViewDidLeave() {
    // console.log("leaae");
    clearInterval(this.interval);
  }

  getCurrency() {
    return this.util.getCurrecySymbol();
  }

  getStatus(status) {
    if (!status) {
      return "";
    }
    return this.util.translate(status);
  }

  hasDriver() {
    if (this.status == 'assigned' || this.status == 'picking_up' || this.status == 'on_route' || this.status == 'arrived') {
      return true
    } else {
      return false
    }
  }

  async mensajeStatus() {
    if (this.info.shippingId) {
      await this.peya_info()
    }
    let driverName = this.cName != "" ? this.cName.split(" ")[0] : this.dName != "" ? this.dName == "Envío por VGo" ? "Tu V-Driver" : this.dName.split(" ")[0] : "";

    // let assignedMessage =
    //   this.cName != ""
    //     ? this.cName.split(" ")[0] + " está yendo a la tienda 🚴"
    //     : this.dName != ""
    //       ? this.dName == "Envío por VGo"
    //         ? "Tu V-Driver está yendo a la tienda 🚴"
    //         : this.dName.split(" ")[0] + " está yendo a la tienda 🚴"
    //       : "";
    // let pickingUpMessage =
    //   this.cName != ""
    //     ? this.cName.split(" ")[0] + " está en la tienda 🙋‍♀️"
    //     : this.dName != ""
    //       ? this.dName == "Envío por VGo"
    //         ? "Tu V-Driver está en la tienda 🙋‍♀️"
    //         : this.dName.split(" ")[0] + " está en la tienda 🙋‍♀️"
    //       : "";

    this.message = {
      created: "La tienda está revisando tu pedido 💭",
      accepted: "Tu pedido está en preparación 🧙‍♂️",
      pending: !this.takeaway ? "Buscando un repartidor cerca 🔍" : "Tu pedido está listo retiralo en la tienda 🔍",
      assigned: driverName + " está yendo a la tienda 🚴",
      picking_up: driverName + " está en la tienda 🙋‍♀️",
      on_route: "Tu pedido está en camino a tu dirección 🚀",
      arrived: "Tu pedido esta en la puerta, disfrutalo 😎",
      delivered: "Tu pedido está entregado 💯",
      cancel: "Tu pedido está cancelado 🙄",
      rejected: "Tu pedido fue rechazado por la tienda 😥",
    };

    this.takeawayMessage = {
      created: "",
      accepted: `Retiro a partir de las ${this.estimatedPreparationTime}.`,
      pending: !this.takeaway ? "Buscando un repartidor cerca 🔍" : `Pedido #${this.id}.`,
      assigned: driverName + " está yendo a la tienda 🚴",
      picking_up: driverName + " está en la tienda 🙋‍♀️",
      on_route: "Tu pedido está en camino a tu dirección 🚀",
      arrived: "Tu pedido esta en la puerta, disfrutalo 😎",
      delivered: "Tu pedido está entregado 💯",
      cancel: "Tu pedido está cancelado 🙄",
      rejected: "Tu pedido fue rechazado por la tienda 😥",
    }
  }
  maps_redirect() {
    window.open('https://www.google.com/maps/search/?api=1&query=' + this.restAddress)
  }
  navigateDetail() {
    const navData: NavigationExtras = {
      queryParams: {
        id: this.id,
      },
    };
    this.router.navigate(["history-detail"], navData);
  }
  probando() {
    let mensaje = {
      tokenNotification: this.restFCM,
      orderId: this.id,
    };
    // console.log("el mensaje", mensaje);

    this.api.sendNotificationVenue({ body: mensaje }).subscribe((data) => {
      // console.log(data);
    });
  }

  async peya_info() {
    let mensaje = {
      shippingId: this.info.shippingId,
    };
    // console.log("el mensaje", mensaje);

    this.api.peya_info({ body: mensaje }).subscribe((data) => {
      // console.log("lo que recibimos", data);
      this.dName = data.deliveryName && data.deliveryName != "Order Cancelled" ? data.deliveryName : null
      this.estimatedPickUpTime = data.estimatedPickUpTime
      this.estimatedDropOffTime = data.estimatedDropOffTime
      this.deliveryTransport = data.deliveryTransport

      const icon = {
        url: "assets/bicycle.svg",
        scaledSize: new google.maps.Size(30, 30), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(15, 15), // anchor
      };
      let driverPos = new google.maps.LatLng(data.latitude, data.longitude);

      this.dMarker2 = new google.maps.Marker({
        map: this.maps,
        position: driverPos,
        animation: google.maps.Animation.DROP,
        icon: icon,
      });
      this.dMarker2.setMap(this.maps);
    });
  }

  changeStatus() {
    if (this.paid == "card") {
      swal
        .fire({
          title: "¿Seguro que querés cancelar?",
          text: "Solicitá el reembolso de tu pago desde What's App",
          showCancelButton: true,
          cancelButtonText: "No",
          showConfirmButton: true,
          confirmButtonText: this.util.translate("Yes"),
          backdrop: false,
          background: "white",
        })
        .then((datas) => {
          // console.log("SEGUNDA DATAS", datas);
          if (datas && datas.value) {
            this.util.show();
            this.api.getProfile(localStorage.getItem("uid")).then(info_user => {
              let amount_of_orders = info_user.orders ? info_user.orders : 0

              if (amount_of_orders) {
                this.orders = amount_of_orders
              } else {
                this.orders = 1
              }
              this.api
                .updateOrderStatus(this.id, "cancel")
                .then(
                  (data) => {
                    this.util.hide();
                    const message =
                      this.util.translate("Order ") +
                      this.id +
                      " " +
                      this.util.translate(" cancelled by user");
                    const title = this.util.translate("Order cancelled");

                    // this.api.sendNotification(message, title, this.driverFCM).subscribe(data => {
                    //   // console.log(data);
                    // });
                    let parmesano = {
                      orders: this.orders - 1
                    }
                    this.api
                      .updateProfile(localStorage.getItem("uid"), parmesano)
                      .then((data) => {
                        console.log("user orders amount change ----->", data);
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    if (this.dId && this.dName) {
                      const parm = {
                        current: "active",
                      };
                      this.api
                        .updateProfile(this.dId, parm)
                        .then((data) => {
                          // console.log("driver status cahcnage----->", data);
                        })
                        .catch((error) => {
                          // console.log(error);
                        });
                    }

                    let mensaje = {
                      tokenNotification: this.restFCM,
                      orderId: this.id,
                    };
                    // console.log("el mensaje", mensaje);

                    this.api
                      .sendNotificationVenue({ body: mensaje })
                      .subscribe((data) => {
                        // console.log(data);
                      });
                    this.openWhatsapp("to be canceled");
                  },
                  (error) => {
                    this.util.hide();
                    // console.log(error);
                    this.util.errorToast("So mething went wrong");
                  }
                )
                .catch((error) => {
                  this.util.hide();
                  // console.log(error);
                  this.util.errorToast("Something went wrong");
                });
            })

          }
        });
    } else {
      swal
        .fire({
          title: "¿Seguro que querés cancelar?",
          text: "",
          showCancelButton: true,
          cancelButtonText: "No",
          showConfirmButton: true,
          confirmButtonText: this.util.translate("Yes"),
          backdrop: false,
          background: "white",
        })
        .then((data) => {
          // console.log("PRIMER DATA", data);
          if (data && data.value) {
            this.util.show();
            this.api
              .updateOrderStatus(this.id, "cancel")
              .then(
                (data) => {
                  this.util.hide();
                  const message =
                    this.util.translate("Order ") +
                    this.id +
                    " " +
                    this.util.translate(" cancelled by user");
                  const title = this.util.translate("Order cancelled");

                  // this.api.sendNotification(message, title, this.driverFCM).subscribe(data => {
                  //   // console.log(data);
                  // });

                  if (this.dId && this.dName) {
                    const parm = {
                      current: "active",
                    };
                    this.api
                      .updateProfile(this.dId, parm)
                      .then((data) => {
                        // console.log("driver status cahcnage----->", data);
                      })
                      .catch((error) => {
                        // console.log(error);
                      });
                  }

                  this.goBack();
                },
                (error) => {
                  this.util.hide();
                  // console.log(error);
                  this.util.errorToast("So mething went wrong");
                }
              )
              .catch((error) => {
                this.util.hide();
                // console.log(error);
                this.util.errorToast("Something went wrong");
              });
          }
        });
    }
  }


  mark_order_as_delivered() {
    const param = {
      status: 'delivered',
      deliveredByUser: new Date(),
      deliveredAt: new Date()
    }
    this.util.show()
    this.api.updateOrder(this.id, param).then(response => {
      this.util.hide()
      const navData: NavigationExtras = {
        queryParams: {
          id: this.id,
        },
      };
      this.router.navigate(["/review-order"], navData);
      console.log("listo a mostrar feedback.")
    })
  }


  openWhatsapp(info?) {

    let estado = "";
    if (this.status == "created") {
      estado = "en revisión";
    } else if (this.status == "accepted") {
      estado = "en preparación";
    } else if (this.status == "pending") {
      estado = "buscando un repartidor";
    } else if (this.status == "assigned") {
      estado = "con un repartidor asignado";
    } else if (this.status == "picking_up") {
      estado = "con el repartidor en la tienda";
    } else if (this.status == "on_route") {
      estado = "en camino con un repartidor";
    } else if (this.status == "arrived") {
      estado = "en mi dirección";
    } else if (this.status == "delivered") {
      estado = "entregado";
    } else if (this.status == "canceled") {
      estado = "cancelado";
    } else if (info == "to be canceled") {
      estado =
        "cancelado y quiero pedir la devolución de mi pago hecho por Mercado Pago.";
    }
    let text =
      "Hola V-Go! Quiero hacerles una consulta sobre mi pedido *N°" +
      this.id +
      "* a " +
      this.name +
      " que esta " +
      estado;
    window.open(
      "https://api.whatsapp.com/send?phone=+5491132053443&text=" + text
    );
  }

  goBack() {
    this.router.navigate(["home"]);
  }

  doRefresh(event) {
    // console.log("Begin async operation");

    this.ngOnInit();

    setTimeout(() => {
      // console.log("Async operation has ended");
      event.target.complete();
    }, 2000);
  }
}
